import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { callRecordingsModel } from '../model';
import { CalendarCard } from './CalendarCard';
import { groupByDate } from '../lib';
import { Button, Icon } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';

const INITIAL_VISIBLE_DAYS = 2;

export const CalendarItemsList = () => {
  const [isLoadedMore, setIsLoadedMore] = useState(false);
  const calendarEvents = useSelector(
    callRecordingsModel.selectors.selectCalendarEvents
  );
  const groupedEventsByDate = useMemo(
    () => groupByDate(calendarEvents),
    [calendarEvents]
  );
  const shouldLoadMore =
    Object.keys(groupedEventsByDate).length > INITIAL_VISIBLE_DAYS &&
    !isLoadedMore;
  const takeDays = shouldLoadMore
    ? INITIAL_VISIBLE_DAYS
    : Object.keys(groupedEventsByDate).length;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-6">
        {Object.keys(groupedEventsByDate)
          .slice(0, takeDays)
          .map((date) => (
            <div key={date} className="text-gray-500">
              <p className="text-xs font-bold uppercase mb-3">{date}</p>
              <div className="flex flex-col gap-1.5">
                {groupedEventsByDate[date].map((event) => (
                  <CalendarCard key={event.recallEventId} event={event} />
                ))}
              </div>
            </div>
          ))}
      </div>

      {shouldLoadMore && (
        <Button
          className="w-full flex gap-1.5"
          variant="text"
          color="secondary"
          onClick={() => setIsLoadedMore(true)}
        >
          Show next 7 days
          <Icon className="-rotate-90" glyph={IconMap.BackArrow} />
        </Button>
      )}
    </div>
  );
};
