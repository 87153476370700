import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Input } from '../../../shared/ui';
import { callModel } from '../model';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

type EditCallItemModalForm = {
  name: string;
  id: string;
};

const validationSchema = object().shape({
  name: string().trim().required('Recording name is required.'),
});

export const EditCallItemModal: React.FC = () => {
  const dispatch = useDispatch();
  const item = useSelector(callModel.selectors.selectEditingCallItemWithError);
  const isLoading = useSelector(
    callModel.selectors.selectIsCallEditingProgress
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<EditCallItemModalForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: item.name ?? '',
    },
  });

  const handleClose = () => {
    reset();
    dispatch(callModel.actions.setEditingCallItem(undefined));
  };
  const handleFormSubmit = ({ name }: { name: string }) => {
    dispatch(
      callModel.actions.updateCallItem({
        id: item.id,
        data: { name },
      })
    );
  };

  return (
    <Modal
      isOpen
      onClose={handleClose}
      title="Rename recording"
      className="w-100"
      actionButton={
        <Button
          onClick={handleSubmit(handleFormSubmit)}
          fullWidth
          type="submit"
          color="primary"
          variant="text"
          loading={isLoading}
        >
          Rename
        </Button>
      }
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Input
          type="text"
          autoComplete="off"
          label="Recording name *"
          placeholder="Enter recording name"
          {...register('name')}
          isError={!!errors.name}
          messageText={errors.name?.message}
        />
      </form>
    </Modal>
  );
};
