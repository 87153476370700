import { TemplateExtended } from '@distribute/shared/api-types/templates';
import { templatesModel } from '..';
import { call, select, put } from 'redux-saga/effects';
import { TemplateCreationPhase } from '@distribute/shared/types';
import { updateTemplateData } from '../../lib';

export function* updateTemplateFromEditor() {
  const template: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const isShowSuccessNotification =
    template.creationPhase !== TemplateCreationPhase.DRAFT;
  const { content } = template;
  const { contentItems } = content;

  yield call(updateTemplateData, {
    template: {
      ...template,
      content: {
        ...content,
        contentItems: contentItems.map((contentItem) => ({
          ...contentItem,
          id: contentItem.id < 0 ? undefined : contentItem.id,
        })),
      },
    } as TemplateExtended,
    isShowSuccessNotification,
  });

  const updatedTemplate: TemplateExtended = yield select(
    templatesModel.selectors.selectCurrentTemplateWithError
  );
  const currentTemplateContentId: number = yield select(
    templatesModel.selectors.selectCurrentTemplateContentId
  );

  /*
   * When we create new tab, it creates locally.
   * When template updates from Editor it saves to the server and we need to update currentTemplateContentId, because it can be changed.
   * */
  if (currentTemplateContentId < 0) {
    const oldContentItem = contentItems.find(
      (item) => item.id === currentTemplateContentId
    );
    const newContentItem = updatedTemplate.content.contentItems.find(
      (item) => item.order === oldContentItem?.order
    );

    if (newContentItem) {
      yield put(
        templatesModel.actions.setCurrentTemplateContentId(newContentItem.id)
      );
    }
  }
}
