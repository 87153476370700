import { RootState } from '../../../app';
import { selectWithError } from '../../../shared/lib';

export const selectTeamCallRecordingSettings = ({
  callRecordingSettings,
}: RootState) => callRecordingSettings.teamSettings;

export const selectUserCallRecordingSettings = ({
  callRecordingSettings,
}: RootState) => callRecordingSettings.userSettings;

export const selectTeamCallRecordingSettingsWithError = selectWithError(
  selectTeamCallRecordingSettings,
  'teamCallSettings'
);

export const selectUserCallRecordingSettingsWithError = selectWithError(
  selectUserCallRecordingSettings,
  'userCallSettings'
);

export const selectMeetingCalendar = ({ callRecordingSettings }: RootState) =>
  callRecordingSettings.meetingCalendar;

export const selectMeetingCalendarWithError = selectWithError(
  selectMeetingCalendar,
  'meetingCalendar'
);

export const selectIsCalendarConnecting = ({
  callRecordingSettings,
}: RootState) => callRecordingSettings.isCalendarConnecting;

export const selectIsMismatchModalOpen = ({
  callRecordingSettings,
}: RootState) => callRecordingSettings.isMismatchModalOpen;

export const selectZoomCredentials = ({ callRecordingSettings }: RootState) =>
  callRecordingSettings.zoomCredentials;

export const selectIsZoomConnecting = ({ callRecordingSettings }: RootState) =>
  callRecordingSettings.isZoomConnecting;

export const selectZoomCredentialsWithError = selectWithError(
  selectZoomCredentials,
  'zoomCredentials'
);
