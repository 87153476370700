import { select, call, put } from 'redux-saga/effects';

import { callRecordingsModel } from '../index';
import { callRecordingsApi } from '../../../../shared/api';
import { MeetingCalendarEvent } from '@distribute/shared/types';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';

export function* loadCalendarEvents() {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callRecordingsModel.actions.setIsLoading(true));

  try {
    const data: MeetingCalendarEvent[] = yield call(
      callRecordingsApi.getCalendarEvents,
      { teamId: team.id }
    );
    yield put(callRecordingsModel.actions.setCalendarEvents(data));
  } catch (err: unknown) {
    // TODO: discuss with Natalia and add error handling
    console.log(err);
  } finally {
    yield put(callRecordingsModel.actions.setIsLoading(false));
  }
}
