import { call, select, put } from 'redux-saga/effects';
import { callRecordingsApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { callRecordingSettingsModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';
import { UserCallSettings } from '@distribute/shared/types';

export function* updateUserCallRecordingSettings({
  payload: data,
}: RT<
  typeof callRecordingSettingsModel.actions.updateUserCallRecordingSettings
>) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  const userSettings: UserCallSettings = yield select(
    callRecordingSettingsModel.selectors
      .selectUserCallRecordingSettingsWithError
  );

  try {
    const response: RT<typeof callRecordingsApi.updateUserSettings> =
      yield call(callRecordingsApi.updateUserSettings, userSettings.id, data, {
        teamId: team.id,
      });
    yield put(callRecordingSettingsModel.actions.setUserSettings(response));
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('success', 'User settings was successfully updated')
      )
    );
  } catch (err) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update user settings')
      )
    );
  }
}
