import { call, select, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { RT } from '../../../../shared/types';
import { callRecordingsModel } from '../index';
import { teamsModel } from '../../../teams';
import { callRecordingsApi } from '../../../../shared/api';
import { MeetingCalendarEvent } from '@distribute/shared/types';
import { createNotification, snackbarModel } from '../../../snackbar';
import { ToggleCalendarEventAction } from '../types';

export function* updateCalendarEvent({
  payload: { eventId, cb },
  type,
}: PayloadAction<ToggleCalendarEventAction>) {
  const updateCalendarEventApi = {
    [callRecordingsModel.actions.toggleCalendarEventRecording.type]:
      callRecordingsApi.toggleCalendarEventRecording,
    [callRecordingsModel.actions.toggleCalendarEventVisibility.type]:
      callRecordingsApi.toggleCalendarEventVisibility,
  };
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);
  const progressCalendarEventIds: RT<
    typeof callRecordingsModel.selectors.selectProgressCalendarEventIds
  > = yield select(
    callRecordingsModel.selectors.selectProgressCalendarEventIds
  );
  const apiCall = updateCalendarEventApi[type];

  try {
    const event: MeetingCalendarEvent = yield call(apiCall, eventId, {
      teamId: team.id,
    });
    const calendarEvents: MeetingCalendarEvent[] = yield select(
      callRecordingsModel.selectors.selectCalendarEvents
    );
    yield put(
      callRecordingsModel.actions.setCalendarEvents(
        calendarEvents.map((calendarEvent) => {
          if (event.recallEventId === calendarEvent.recallEventId) {
            return event;
          }
          return calendarEvent;
        })
      )
    );
    cb?.();
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed update calendar event')
      )
    );
    cb?.(err);
  } finally {
    //TODO: fix that (progress for make private / toggle visibility)
    yield put(
      callRecordingsModel.actions.setProgressCalendarEventIds(
        progressCalendarEventIds.filter((id) => id !== eventId)
      )
    );
  }
}
