import {
  TeamCallSettings as TeamCallSettingsEntity,
  UserCallSettings as UserCallSettingsEntity,
  MeetingCalendar as MeetingCalendarEntity,
  MeetingCalendarEvent as MeetingCalendarEventEntity,
  UserZoomCredentials,
} from '@distribute/shared/types';

export type GetMeetingCalendarEventResponse = MeetingCalendarEventEntity;
export type GetMeetingCalendarEventDataResponse =
  MeetingCalendarEventEntity['eventData'];

export type GetMeetingCalendarResponse = MeetingCalendarEntity;
export type GetTeamCallSettingsResponse = TeamCallSettingsEntity;
export type GetUserCallSettingsResponse = UserCallSettingsEntity;
export type GetZoomCredentialsResponse = UserZoomCredentials;

export type GetCallRecorderSettingsResponse = {
  meetingCalendar: GetMeetingCalendarResponse | null;
  teamSettings: GetTeamCallSettingsResponse;
  userSettings: GetUserCallSettingsResponse;
  zoomCredentials: GetZoomCredentialsResponse | null;
};

export type ConnectOAuthGoogleCalendarResponse = {
  url: string;
};

export type ConnectOAuthGoogleCalendarQueryParams = {
  teamId: number;
  redirectPath: string;
};

export type ConnectOAuthZoomResponse = {
  url: string;
};

export type ConnectOAuthZoomQueryParams = {
  redirectPath: string;
};

export type UpdateTeamCallSettingsRequest = Partial<
  Omit<TeamCallSettingsEntity, 'id' | 'createdAt' | 'updatedAt' | 'teamId'>
>;
export type UpdateUserCallSettingsRequest = Partial<
  Omit<UserCallSettingsEntity, 'id' | 'createdAt' | 'updatedAt'>
>;

export const callbackOAuthStatusQueryKey = 'oauth-status';

export enum CallBackOAuthStatus {
  GOOGLE_SUCCESS = 'google-success',
  GOOGLE_FAILED = 'google-failed',
  GOOGLE_EMAIL_MISMATCH = 'google-email-mismatch',
  ZOOM_SUCCESS = 'zoom-success',
  ZOOM_FAILED = 'zoom-failed',
}
