import React from 'react';
import { useSelector } from 'react-redux';
import { callRecordingsModel } from '../model';
import { callRecordingSettingsModel } from '../../call-recording-settings';
import { Loader } from '../../../shared/ui';
import { CalendarItemsList } from './CalendarItemsList';
import { ConnectCalendarState } from './ConnectCalendarState';

export const CalendarEventsState = () => {
  const isCalendarEventsLoading = useSelector(
    callRecordingsModel.selectors.selectIsLoading
  );
  const meetingsCalendar = useSelector(
    callRecordingSettingsModel.selectors.selectMeetingCalendar
  );

  if (isCalendarEventsLoading) {
    return (
      <div className="flex items-center flex-1">
        <Loader />
      </div>
    );
  }
  if (meetingsCalendar) {
    return <CalendarItemsList />;
  }

  return (
    <div className="flex items-center flex-1">
      <ConnectCalendarState size="small" />
    </div>
  );
};
