import { takeEvery, takeLatest, call, put, debounce } from 'redux-saga/effects';
import { callRecordingSettingsModel } from '../../../features/call-recording-settings';
import { callRecordingsModel } from '../../../features/call-recordings';
import { callModel } from '../../../features/call';
import { fetchCalls } from '../../../features/call/model/sagas';

export function* callRecordingsWorker() {
  yield put(callModel.actions.reset());
  yield takeEvery(
    callRecordingsModel.actions.connectToGoogleCalendar,
    callRecordingSettingsModel.sagas.connectToGoogleCalendar
  );

  yield takeEvery(
    callRecordingsModel.actions.updateJoinPreference,
    callRecordingSettingsModel.sagas.updateUserCallRecordingSettings
  );

  yield takeEvery(
    callRecordingsModel.actions.toggleCalendarEventRecording,
    callRecordingsModel.sagas.updateCalendarEvent
  );

  yield takeEvery(
    callRecordingsModel.actions.toggleCalendarEventVisibility,
    callRecordingsModel.sagas.updateCalendarEvent
  );

  yield takeLatest(callModel.actions.loadCalls, callModel.sagas.loadCalls);

  yield takeEvery(
    callModel.actions.deleteCallItem,
    callModel.sagas.deleteCallItem
  );

  yield takeEvery(
    callModel.actions.updateCallItem,
    callModel.sagas.updateCallItem
  );

  yield call(callModel.sagas.loadCalls);
  yield call(callRecordingsModel.sagas.loadCalendarEvents);

  yield debounce(300, callModel.actions.searchCalls, fetchCalls);
}
