import React from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { ConnectCalendarState } from '../../../features/call-recordings';
import { callRecordingSettingsModel } from '../../../features/call-recording-settings';
import { callModel } from '../../../features/call';
import { Button, Link, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { MeetingsSidebar } from '../../../features/call-recordings';
import { navigate } from '../../../processes/navigation';
import { CallList } from '../../../features/call';

export const CallRecordingsPage = () => {
  const dispatch = useDispatch();
  const meetingsCalendar = useSelector(
    callRecordingSettingsModel.selectors.selectMeetingCalendarWithError
  );
  const callList = useSelector(callModel.selectors.selectCallList);

  const shouldShowCalls = meetingsCalendar || callList.length > 0;

  const handleNavigateToSettings = () => {
    dispatch(
      navigate({
        to: '/team-settings/call-recordings',
      })
    );
  };

  return (
    <div className="flex flex-col min-w-0 flex-grow-1 pb-8">
      <Helmet titleTemplate="Call Recordings - Distribute" />

      <header className="flex justify-between gap-4 mb-5">
        <h1 className="font-medium text-gray-900 text-display-sm font-display">
          Call Recordings
        </h1>
        <div className="flex gap-2 items-center">
          <Button
            onClick={handleNavigateToSettings}
            variant="icon-text"
            color="secondary"
            iconLeftName={IconMap.Settings}
          >
            Settings
          </Button>
          <Button variant="text" color="primary">
            Join Call
          </Button>
        </div>
      </header>

      <div className="flex flex-1 min-h-0 justify-between relative gap-6">
        <div className="flex-1">
          {shouldShowCalls ? (
            <div className="flex flex-col h-full pt-3">
              <CallList />
            </div>
          ) : (
            <div className="h-full flex items-center justify-center">
              <ConnectCalendarState size="large" />
            </div>
          )}
        </div>
        {shouldShowCalls && <MeetingsSidebar />}
      </div>
      {!shouldShowCalls && (
        <footer>
          <div className="text-center">
            <Tooltip
              className="max-w-80"
              hideArrow
              trigger={
                <Link size="large">
                  Why Distribute need access to your calendar?
                </Link>
              }
            >
              <div className="text-xs p-3">
                <strong className="text-base-white font-semibold">
                  Why Distribute need access to your calendar?
                </strong>
                <p className="text-gray-300 font-medium pt-1">
                  Distribute accesses your calendar events with links and
                  displays them for you. You can then choose which events
                  you&apos;d like Distribute to join and record, giving you
                  complete control over the sessions you want recorded.
                </p>
              </div>
            </Tooltip>
          </div>
        </footer>
      )}
    </div>
  );
};
