import { GetCallResponse } from '@distribute/shared/api-types/call';

import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { globalActions } from '../../../app/model/actions';

import { DeleteCallItemAction, UpdateCallItemAction } from './types';

type State = {
  isListLoading: boolean;
  calls: GetCallResponse[];
  editingCallItem?: GetCallResponse;
  deletingCallItem?: GetCallResponse;
  isCallEditingProgress: boolean;
  isCallDeletingProgress: boolean;
  listOptions: {
    teamOnly?: boolean;
    search?: string;
    sortOrder?: 'ASC' | 'DESC';
    sortBy?: 'name' | 'createdAt';
    filterByMember?: string;
  };
  listMeta: {
    totalItems?: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages?: number;
    currentPage: number;
  };
};

const initialState: State = {
  isListLoading: false,
  calls: [],
  editingCallItem: undefined,
  deletingCallItem: undefined,
  isCallEditingProgress: false,
  isCallDeletingProgress: false,
  listOptions: {
    teamOnly: undefined,
    search: undefined,
    sortOrder: undefined,
    sortBy: 'createdAt',
    filterByMember: undefined,
  },
  listMeta: {
    totalItems: 0,
    itemCount: 0,
    itemsPerPage: 10,
    totalPages: 0,
    currentPage: 1,
  },
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setIsListLoading: (
      state,
      { payload }: PayloadAction<State['isListLoading']>
    ) => ({
      ...state,
      isListLoading: payload,
    }),
    setListOptions: (
      state,
      { payload }: PayloadAction<State['listOptions']>
    ) => ({
      ...state,
      listOptions: {
        ...state.listOptions,
        ...payload,
      },
    }),
    setListMeta: (state, { payload }: PayloadAction<State['listMeta']>) => ({
      ...state,
      listMeta: payload,
    }),
    setCalls: (state, { payload }: PayloadAction<State['calls']>) => ({
      ...state,
      calls: payload,
    }),
    setEditingCallItem: (
      state,
      { payload }: PayloadAction<State['editingCallItem']>
    ) => ({
      ...state,
      editingCallItem: payload,
    }),
    setDeletingCallItem: (
      state,
      { payload }: PayloadAction<State['deletingCallItem']>
    ) => ({
      ...state,
      deletingCallItem: payload,
    }),
    setCallEditingProgress: (
      state,
      { payload }: PayloadAction<State['isCallEditingProgress']>
    ) => ({
      ...state,
      isCallEditingProgress: payload,
    }),
    setCallDeletingProgress: (
      state,
      { payload }: PayloadAction<State['isCallDeletingProgress']>
    ) => ({
      ...state,
      isCallDeletingProgress: payload,
    }),
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  loadCalls: createAction('call/loadCalls'),
  searchCalls: createAction('call/searchCalls'),
  deleteCallItem: createAction<DeleteCallItemAction>('call/deleteCallItem'),
  updateCallItem: createAction<UpdateCallItemAction>('call/updateCallItem'),
};
