import React from 'react';
import { useDispatch } from 'react-redux';
import { GetCallResponse } from '@distribute/shared/api-types/call';
import { callModel } from '../model';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Dropdown, Icon, Avatar, TimeAgo } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { getMuxAssetImageByPolicy } from '@distribute/shared/utils';
import { MuxAssetStatus } from '@distribute/shared/types';
import { formatMsToTimePassed } from '@distribute/shared/utils';

type CallItemProps = {
  item: GetCallResponse;
};

export const CallItem: React.FC<CallItemProps> = ({ item }) => {
  const dispatch = useDispatch();
  const { isPrivate, userToTeam, name, createdAt, muxAsset } = item;

  const owner = userToTeam?.user;
  const isMuxAssetReady = muxAsset.status === MuxAssetStatus.READY;
  const assetImagePath = getMuxAssetImageByPolicy(muxAsset);

  const toggleVisibility = () => {
    dispatch(
      callModel.actions.updateCallItem({
        id: item.id,
        data: { isPrivate: !isPrivate },
      })
    );
  };
  const handleEdit = () => {
    dispatch(callModel.actions.setEditingCallItem(item));
  };
  const handleDelete = () => {
    dispatch(callModel.actions.setDeletingCallItem(item));
  };

  const callControls = [
    {
      label: isPrivate ? 'Make public' : 'Make private',
      id: 'public',
      iconName: IconMap.Users02,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: toggleVisibility,
    },
    {
      label: 'Rename',
      id: 'rename',
      isSeparatedFromTop: true,
      iconName: IconMap.EditIcon,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleEdit,
    },
    {
      label: 'Delete',
      id: 'delete',
      iconName: IconMap.Trash,
      iconWidth: 16,
      style: 'sm:hidden',
      onClick: handleDelete,
    },
  ];

  return (
    <div className="group/item border border-gray-300 bg-base-white rounded-lg p-3 w-83">
      <div className="relative mb-4">
        {isMuxAssetReady ? (
          <div className="relative w-full h-41.5 border border-base-black-8 rounded-lg overflow-hidden">
            <img src={assetImagePath} alt={item.name} />
            <span className="absolute bottom-2 right-2 bg-base-black/70 text-base-white py-0.5 px-2.25 rounded-2xl text-sm">
              {formatMsToTimePassed(muxAsset.data.duration * 1000)}
            </span>
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center gap-4 w-full h-41.5 border-base-black-8 bg-gray-50 rounded-lg">
            <Icon
              className="text-gray-600"
              glyph={IconMap.FilmArrow}
              width={24}
            />
            <div className="text-center">
              <h3 className="text-gray-700">Uploading...</h3>
              <p className="text-gray-400 text-sm">
                Recording will be available soon
              </p>
            </div>
          </div>
        )}
        <Dropdown
          listStyles="shadow-lg"
          isModalMode={false}
          triggerComponent={
            <DropdownMenu.Trigger
              className="absolute top-2 right-2.25
                flex items-center justify-center w-10 h-10
                border border-gray-200 rounded-lg focus:outline-none hover:border-gray-300
                bg-base-white/70 hover:bg-base-white
                invisible group-hover/item:visible"
            >
              <Icon glyph={IconMap.DotsVertical} width={20} />
            </DropdownMenu.Trigger>
          }
          items={callControls}
        />
      </div>

      <div className="flex flex-col gap-1.5 text-gray-700">
        {owner && (
          <div className="flex gap-1 items-center text-xs">
            <Avatar
              src={owner.photoUrl}
              displayName={owner.displayName}
              size="3xs"
            />
            by{' '}
            <span className="text-gray-900 font-semibold">
              {owner.displayName}
            </span>
          </div>
        )}
        <h3 className="font-semibold truncate">{name}</h3>
        <div className="flex items-center justify-between text-sm text-gray-600">
          <TimeAgo datetime={createdAt} locale="en_US" />
          {!isPrivate && (
            <p className="flex items-center gap-0.5">
              <Icon glyph={IconMap.Users01} width={16} />
              Team access
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
