import { call, put, select } from 'redux-saga/effects';
import { callApi } from '../../../../shared/api';
import { callModel } from '../index';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* updateCallItem({
  payload: { id, data },
}: ReturnType<typeof callModel.actions.updateCallItem>) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callModel.actions.setCallEditingProgress(true));

  try {
    yield call(callApi.updateCallItem, id, data, { teamId: team.id });
    yield call(callModel.sagas.loadCalls);
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to update call recording')
      )
    );
  } finally {
    yield put(callModel.actions.setCallEditingProgress(false));
  }
}

export function* deleteCallItem({
  payload: { id },
}: ReturnType<typeof callModel.actions.deleteCallItem>) {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  yield put(callModel.actions.setCallDeletingProgress(true));

  try {
    yield call(callApi.deleteItem, id, { teamId: team.id });
    yield call(callModel.sagas.loadCalls);
  } catch (err: unknown) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', 'Failed to remove call recording')
      )
    );
  } finally {
    yield put(callModel.actions.setCallDeletingProgress(false));
  }
}
