import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MeetingCalendarEvent } from '@distribute/shared/types';
import { getDateTime } from '../lib';
import { Toggle, Icon, Avatar, Tooltip } from '../../../shared/ui';
import { IconMap } from '../../../shared/sprite';
import { callRecordingsModel } from '../model';

type CalendarCardProps = {
  event: MeetingCalendarEvent;
};

const MeetingPlatforms = {
  googleMeet: {
    name: 'On Google Meet',
    icon: IconMap.GoogleMeet,
  },
  zoom: {
    name: 'on Zoom',
    icon: IconMap.ZoomCall,
  },
  teams: {
    name: 'on Teams',
    icon: IconMap.TeamsCall,
  },
} as const;

export const CalendarCard: React.FC<CalendarCardProps> = ({ event }) => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const {
    name,
    startTime,
    endTime,
    shouldRecord,
    meetingPlatform,
    eventData,
    isPrivate,
  } = event;
  const [isEventPrivate, setIsEventPrivate] = useState(isPrivate);
  const [shouldRecordEvent, setShouldRecordEvent] = useState(!!shouldRecord);
  const currentPlatform = MeetingPlatforms[meetingPlatform ?? 'googleMeet'];
  const userList = eventData.attendees
    .map((attendee) => attendee.email)
    .filter(Boolean);
  const progressCalendarId = useSelector(
    callRecordingsModel.selectors.selectProgressCalendarEventIds
  );
  const isProgress = progressCalendarId.includes(event.recallEventId);

  const toggleEventRecording = () => {
    setShouldRecordEvent(!shouldRecordEvent);
    dispatch(
      callRecordingsModel.actions.toggleCalendarEventRecording({
        eventId: event.recallEventId,
        cb: (err: unknown) => {
          if (err) {
            setShouldRecordEvent(!!shouldRecord);
          }
        },
      })
    );
  };

  const toggleEventVisibility = () => {
    setIsEventPrivate(!isEventPrivate);
    dispatch(
      callRecordingsModel.actions.toggleCalendarEventVisibility({
        eventId: event.recallEventId,
        cb: (err: unknown) => {
          if (err) {
            setIsEventPrivate(isPrivate);
          }
        },
      })
    );
  };

  return (
    <div className="group/event bg-gray-100 rounded-lg p-4">
      <header className="flex justify-between pb-3 items-start gap-1 text-gray-900">
        <div className="max-w-4/5">
          <h4 className="text-sm font-semibold truncate">{name}</h4>
          <p className="text-xs text-gray-500">{`${getDateTime(
            startTime
          )} - ${getDateTime(endTime)}`}</p>
        </div>
        <div className="flex gap-0.5 items-center">
          <Tooltip
            hideArrow
            triggerClassNames="h-5 invisible group-hover/event:visible"
            trigger={
              <button className="outline-none" onClick={toggleEventVisibility}>
                <Icon
                  glyph={isEventPrivate ? IconMap.Users02 : IconMap.Lock01}
                  width={20}
                />
              </button>
            }
          >
            {isEventPrivate ? 'Make public' : 'Make private'}
          </Tooltip>
          <Toggle
            checked={shouldRecordEvent}
            onChange={toggleEventRecording}
            disabled={isProgress}
          />
        </div>
      </header>
      <div
        className={cn('flex justify-between gap-2 text-xs text-gray-500', {
          'justify-between': userList.length > 1,
          'justify-end': userList.length <= 1,
        })}
      >
        {userList.length > 1 ? (
          <DropdownMenu.Root
            open={isDropdownOpen}
            onOpenChange={setIsDropdownOpen}
          >
            <DropdownMenu.Trigger className="flex gap-1.5 items-center bg-base-white p-1 rounded-full focus:outline-none">
              <Icon glyph={IconMap.Lead} width={16} />
              {userList.length} people
              <Icon
                className={cn('text-gray-700 transition-all', {
                  'rotate-180': isDropdownOpen,
                })}
                glyph={IconMap.ArrowDown}
                width={16}
              />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content
              align="start"
              sideOffset={4}
              className="bg-base-white border border-gray-300 rounded-md shadow-lg w-64 p-3 text-gray-500"
            >
              {userList.map((user) => (
                <DropdownMenu.Item
                  key={user}
                  className="flex items-center gap-3 py-1.25 focus:outline-none"
                >
                  <Avatar src={undefined} displayName={user} />
                  <span className="truncate">{user}</span>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        ) : null}
        <div className="flex items-center gap-2 mt-2">
          <Icon glyph={currentPlatform.icon} width={20} />
          <span>{currentPlatform.name}</span>
        </div>
      </div>
    </div>
  );
};
