import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
  Page,
  PageTeamMembersPermission,
  Task,
  TasklistWithTasks,
} from '@distribute/shared/types';
import {
  Avatar,
  Dropdown,
  Icon,
  Modal,
  Tooltip,
} from '../../../../src/shared/ui';
import { IconMap } from '../../../../src/shared/sprite';
import { useDispatch, useSelector } from 'react-redux';
import { teamsModel } from '../../teams';
import { pagesModel } from '../../pages';
import { TaskModalContent } from './TaskModalContent';
import { JSONContent } from '@tiptap/core';
import { useOnClickOutside } from '../../../../src/shared/hooks/useClickOutside';
import { DueDateCalendar } from './DueDateCalendar';
import { AssigneeTrigger } from './AssigneeTrigger';
import { format } from 'date-fns';
import { tasksModel } from '../model';
import { cn } from '@distribute/frontend/utils';
import ReactTextareaAutosize from 'react-textarea-autosize';

type Props = {
  task: Task;
  tasklistId: string;
  changeAttributeForDataUpdate: () => void;
  documentContentId: number;
  setTasklist: Dispatch<SetStateAction<TasklistWithTasks>>;
  isDragging?: boolean;
};

export type UpdateTaskInput = {
  isDone?: boolean;
  isVisible?: boolean;
  title?: string;
  dueDate?: Date | null;
  description?: string;
  notes?: JSONContent;
};

export const TaskItem: FC<Props> = ({
  task,
  tasklistId,
  changeAttributeForDataUpdate,
  documentContentId,
  setTasklist,
  isDragging,
}) => {
  const dispatch = useDispatch();

  const tasklist = useSelector(tasksModel.selectors.selectTasklists)[
    tasklistId
  ];
  const originalTask = tasklist.tasks.find((t) => t.id === task.id);

  const [currentTask, setCurrentTask] = useState(task);
  const [assignee, setAssignee] = useState(task.assignee);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionOpen, setIsActionOpen] = useState(false);

  const page: Page | undefined = useSelector(
    pagesModel.selectors.selectCurrentPage
  );

  const teamMembersPermission = page?.teamMembersPermission || null;

  const currentTeamMembers = useSelector(
    teamsModel.selectors.selectCurrentTeamMembers
  );

  const calendarRef = useRef(null);
  const actionsRef = useRef(null);

  const handleTaskUpdate = (data: UpdateTaskInput) => {
    if (!originalTask) {
      return;
    }
    setCurrentTask((prevCurrentTask) => {
      const updatedTaskData = { ...prevCurrentTask, ...data };

      return updatedTaskData;
    });

    const urlParams = new URLSearchParams(window.location.search);
    const tabId = urlParams.get('tab');

    dispatch(
      tasksModel.actions.updateTask({
        ...data,
        pageId: page?.id,
        tabId: tabId || undefined,
        documentContentId,
        taskId: task.id,
        tasklistId: tasklistId,
        successCallback: changeAttributeForDataUpdate,
        failedCallback: () => {
          setCurrentTask(originalTask);
        },
      })
    );
  };

  const removeTaskAssignee = useCallback(() => {
    if (!originalTask) {
      return;
    }

    setAssignee(undefined);
    dispatch(
      tasksModel.actions.removeTaskAssignee({
        taskId: task.id,
        tasklistId,
        documentContentId,
        successCallback: changeAttributeForDataUpdate,
        failedCallback: () => {
          setAssignee(originalTask.assignee);
        },
      })
    );
  }, [
    setAssignee,
    changeAttributeForDataUpdate,
    dispatch,
    task,
    documentContentId,
    tasklistId,
    originalTask,
  ]);

  const duplicateTask = useCallback(() => {
    dispatch(
      tasksModel.actions.duplicateTask({
        taskId: task.id,
        tasklistId,
        documentContentId,
        successCallback: (updatedTasklist: TasklistWithTasks) => {
          setTasklist(updatedTasklist);
          changeAttributeForDataUpdate();
        },
      })
    );
  }, [
    setTasklist,
    changeAttributeForDataUpdate,
    dispatch,
    tasklistId,
    documentContentId,
    task,
  ]);

  const deleteTask = useCallback(async () => {
    dispatch(
      tasksModel.actions.deleteTask({
        taskId: task.id,
        tasklistId,
        documentContentId,
        successCallback: (updatedTasklist: TasklistWithTasks) => {
          setTasklist(updatedTasklist);
          changeAttributeForDataUpdate();
        },
      })
    );
  }, [
    setTasklist,
    changeAttributeForDataUpdate,
    dispatch,
    task.id,
    documentContentId,
    tasklistId,
  ]);

  const onActionsButtonClick = (event: React.MouseEvent) => {
    if (isDragging) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    setIsActionOpen((prevIsOpen) => !prevIsOpen);
  };

  const userPermissionsToPageAndTeam = useMemo(() => {
    if (!page) {
      return null;
    }
    return page.userPermissionToPages.map((permission) => ({
      userToPage: permission,
      userToTeam: currentTeamMembers.find(
        (item) =>
          (item.user?.email ?? item.externalUserEmail) ===
          (permission.user?.email ?? permission.externalUserEmail)
      ),
    }));
  }, [currentTeamMembers, page]);

  const usersWithAccess = useMemo(() => {
    if (
      teamMembersPermission === PageTeamMembersPermission.EDIT ||
      teamMembersPermission === PageTeamMembersPermission.VIEW
    ) {
      return currentTeamMembers;
    }

    return userPermissionsToPageAndTeam?.map((u) => u.userToPage);
  }, [teamMembersPermission, userPermissionsToPageAndTeam, currentTeamMembers]);

  const clearAssigneeOptions = useMemo(() => {
    if (!assignee) {
      return [];
    }

    return [
      {
        id: 'clear-assignee',
        label: (
          <div
            className={cn('flex items-center gap-2.5', {
              hidden: !assignee,
            })}
          >
            <div className="flex items-center justify-center w-5 h-5">
              <Icon
                glyph={IconMap.Cross}
                width={12}
                className="text-gray-500"
              />
            </div>
            <div className="flex flex-col min-w-0 font-medium text-gray-700 truncate text-md group-hover:text-gray-800">
              Clear
            </div>
          </div>
        ),
        onClick: removeTaskAssignee,
      },
    ];
  }, [assignee, removeTaskAssignee]);

  const assigneeOptions = useMemo(() => {
    if (!usersWithAccess) {
      return [];
    }

    const options = [
      ...clearAssigneeOptions,
      ...usersWithAccess.map((member, i) => {
        return {
          id: member.id.toString(),
          isSeparatedFromTop: assignee && i === 0,
          label: (
            <div className="flex items-center gap-2">
              <Avatar
                displayName={
                  member.user?.displayName || member.externalUserEmail || ''
                }
                src={member.user?.photoUrl}
                size="xs"
                isUserAvatar
                className="border-light-7 border-[0.5px] !bg-base-white"
              />
              <div className="flex flex-col min-w-0 font-medium text-gray-700 truncate text-md group-hover:text-gray-800">
                {member.user?.displayName || member.externalUserEmail || ''}
              </div>
            </div>
          ),
          onClick: async () => {
            if (!page) {
              return;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const tabId = urlParams.get('tab');

            dispatch(
              tasksModel.actions.setTaskAssignee({
                taskId: task.id,
                tasklistId,
                documentContentId,
                email: member.user?.email || member.externalUserEmail || '',
                pageId: page.id || '',
                tabId: tabId || '',
                successCallback: (assignee) => {
                  setAssignee(assignee);
                  changeAttributeForDataUpdate();
                },
                failedCallback: () => {
                  setAssignee(task.assignee);
                },
              })
            );
          },
        };
      }),
    ];

    return options;
  }, [
    usersWithAccess,
    clearAssigneeOptions,
    dispatch,
    setAssignee,
    changeAttributeForDataUpdate,
    assignee,
    documentContentId,
    page,
    task.assignee,
    task.id,
    tasklistId,
  ]);

  const actionOptions = useMemo(() => {
    const options = [
      {
        id: 'duplicate-task',
        label: (
          <div className="flex items-center gap-3 p-2.5 rounded-md hover:bg-base-black-4 hover:text-gray-700 text-gray-600">
            <div className="flex items-center justify-center w-5 h-5">
              <Icon
                glyph={IconMap.Duplicate}
                width={16}
                className="text-gray-500"
              />
            </div>
            <div className="flex flex-col min-w-0 text-sm text-gray-700 truncate group-hover:text-gray-800">
              Duplicate
            </div>
          </div>
        ),
        onClick: duplicateTask,
      },
      {
        id: 'delete-task',
        label: (
          <div className="flex items-center gap-3 p-2.5 rounded-md hover:bg-base-black-4 hover:text-gray-700 text-gray-600">
            <div className="flex items-center justify-center w-5 h-5">
              <Icon
                glyph={IconMap.Delete}
                width={16}
                className="text-gray-500"
              />
            </div>
            <div className="flex flex-col min-w-0 text-sm text-gray-700 truncate group-hover:text-gray-800">
              Delete
            </div>
          </div>
        ),
        onClick: deleteTask,
      },
    ];

    return options;
  }, [duplicateTask, deleteTask]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const taskId = urlParams.get('taskId');

    if (taskId === task.id) {
      setIsModalOpen(true);
    }
  }, [setIsModalOpen, task.id]);

  useEffect(() => {
    setCurrentTask(task);
    setAssignee(task.assignee);
  }, [task, setCurrentTask, setAssignee]);

  const clickCalendarOutsideHandler = () => {
    setIsCalendarOpen(false);
  };

  const clickActionsOutsideHandler = () => {
    setIsActionOpen(false);
  };

  useOnClickOutside(calendarRef, clickCalendarOutsideHandler);
  useOnClickOutside(actionsRef, clickActionsOutsideHandler);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        isShowCancelButton={false}
        onClose={() => {
          setIsModalOpen(false);
        }}
        className="w-240 h-[min(800px,100vh-40px)] flex flex-col md:w-182 md:min-w-182 px-25 py-10"
      >
        <TaskModalContent
          onClose={() => {
            setIsModalOpen(false);
          }}
          task={currentTask}
          assigneeOptions={assigneeOptions}
          assignee={assignee}
          onTaskUpdate={handleTaskUpdate}
        />
      </Modal>

      <div
        data-type="customTaskListItem"
        className="relative py-4 pl-0 pr-5 mb-4 rounded-lg bg-gray-50 hover:bg-gray-100 group"
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-grow">
            <Tooltip
              trigger={
                <div className="relative w-5 h-5.5" ref={actionsRef}>
                  <button
                    className="items-center justify-center hidden w-5 h-5.5 rounded-lg hover:bg-gray-25 group-hover:flex top-0.5"
                    onClick={onActionsButtonClick}
                  >
                    <Icon
                      glyph={IconMap.Drag}
                      className="text-gray-400"
                      width={20}
                    />
                  </button>
                  {isActionOpen && (
                    <div className="absolute shadow-xs left-0 p-1.5 border border-gray-200 top-6 min-w-40 bg-base-white rounded-xl z-1">
                      {actionOptions.map((action) => (
                        <button
                          key={action.id}
                          onClick={action.onClick}
                          className="w-full"
                        >
                          {action.label}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              }
              sideOffset={4}
            >
              Click to open menu
            </Tooltip>
            <div className="flex items-center flex-grow gap-3">
              <label
                className="relative inline-block w-5 h-5"
                contentEditable={false}
              >
                <input
                  type="checkbox"
                  className="absolute w-full h-full opacity-0 cursor-pointer"
                  checked={currentTask.isDone}
                  onChange={(e) => {
                    handleTaskUpdate({ isDone: e.target.checked });
                  }}
                />
                <span
                  className={cn(
                    'border border-gray-300 rounded w-5 h-5 flex flex-col justify-center items-center',
                    {
                      'bg-primary-solid border-primary-solid':
                        currentTask.isDone,
                      'bg-base-white border-gray-300': !currentTask.isDone,
                    }
                  )}
                >
                  {currentTask.isDone && (
                    <Icon
                      className="w-3 h-3 text-base-white"
                      glyph={IconMap.Check}
                    />
                  )}
                </span>
              </label>
              <div className="relative flex flex-grow">
                <ReactTextareaAutosize
                  placeholder="Untitled"
                  className={cn(
                    'flex-grow text-gray-900 text-lg font-semibold border-none outline-none placeholder:text-gray-400 bg-transparent w-full resize-none',
                    {
                      'line-through': currentTask.isDone,
                    }
                  )}
                  maxLength={200}
                  minRows={1}
                  maxRows={6}
                  value={currentTask.title}
                  onChange={(e) => {
                    handleTaskUpdate({ title: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <Tooltip
              hideArrow
              sideOffset={8}
              className="py-2 text-center"
              triggerClassNames="flex"
              trigger={
                <button
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <Icon
                    glyph={IconMap.Expand01}
                    width={20}
                    className="text-gray-600"
                  />
                </button>
              }
            >
              Expand
            </Tooltip>

            <Tooltip
              hideArrow
              sideOffset={8}
              className="py-2 text-center"
              triggerClassNames="flex"
              trigger={
                <button
                  onClick={() => {
                    handleTaskUpdate({ isVisible: !currentTask.isVisible });
                  }}
                >
                  <Icon
                    glyph={
                      currentTask.isVisible ? IconMap.Eye : IconMap.CrossedEye
                    }
                    width={20}
                    className="text-gray-600"
                  />
                </button>
              }
            >
              {currentTask.isVisible
                ? 'Visible for viewers'
                : 'Invisible for viewers'}
            </Tooltip>

            {page && (
              <Dropdown
                listStyles="shadow-lg min-w-55"
                triggerComponent={
                  <Tooltip
                    hideArrow
                    sideOffset={8}
                    trigger={
                      <DropdownMenu.Trigger className="flex items-center justify-center">
                        {assignee ? (
                          <AssigneeTrigger assignee={assignee} />
                        ) : (
                          <div className="flex items-center justify-center w-6 h-6 border border-gray-300 border-dashed rounded-full bg-base-white">
                            <Icon
                              glyph={IconMap.Plus}
                              className="text-gray-400"
                              width={16}
                            />
                          </div>
                        )}
                      </DropdownMenu.Trigger>
                    }
                  >
                    {assignee ? (
                      <div>
                        {assignee.user?.displayName && (
                          <div>{assignee.user?.displayName}</div>
                        )}
                        <div>{assignee.email}</div>
                      </div>
                    ) : (
                      'Assign task'
                    )}
                  </Tooltip>
                }
                alignOffset={-100}
                items={assigneeOptions}
                isModalMode={false}
              />
            )}

            <div className="relative">
              <Tooltip
                hideArrow
                sideOffset={8}
                className="flex flex-col py-2"
                triggerClassNames="flex"
                trigger={
                  <button
                    className="flex flex-col items-end"
                    onClick={() => {
                      setIsCalendarOpen((prevOpen) => !prevOpen);
                    }}
                  >
                    <div className="flex items-center gap-2">
                      <Icon
                        glyph={IconMap.Calendar}
                        width={20}
                        className={cn({
                          'text-gray-500': currentTask.dueDate,
                          'text-gray-400': !currentTask.dueDate,
                        })}
                      />
                      <span
                        className={cn('text-md', {
                          'text-gray-600 font-medium': currentTask.dueDate,
                          'text-gray-400': !currentTask.dueDate,
                        })}
                      >
                        {currentTask.dueDate
                          ? format(new Date(currentTask.dueDate), 'MMM d')
                          : 'none'}
                      </span>
                    </div>
                  </button>
                }
              >
                <span>Due date</span>
                {currentTask.dueDate && (
                  <span>
                    {format(new Date(currentTask.dueDate), 'MMMM d, yyyy')}
                  </span>
                )}
              </Tooltip>
              {isCalendarOpen && (
                <div
                  ref={calendarRef}
                  className="absolute px-6 pt-5 pb-4 border border-gray-200 z-[10000] top-8 -right-5 w-82 bg-base-white rounded-xl"
                >
                  <DueDateCalendar
                    dueDate={currentTask.dueDate}
                    onDueDateChange={(value: Date | null | (Date | null)[]) => {
                      if (Array.isArray(value)) {
                        return;
                      }
                      handleTaskUpdate({ dueDate: value });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
