import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  MeetingCalendarEvent,
  UserCallSettings,
} from '@distribute/shared/types';
import { globalActions } from '../../../app/model/actions';
import { ToggleCalendarEventAction } from './types';

type State = {
  isLoading: boolean;
  calendarEvents: MeetingCalendarEvent[];
  progressCalendarEventIds: Array<string>;
};

const initialState: State = {
  isLoading: false,
  calendarEvents: [],
  progressCalendarEventIds: [],
};

export const { reducer, actions: reducerActions } = createSlice({
  name: 'callRecordings',
  initialState,
  reducers: {
    setCalendarEvents: (
      state,
      { payload }: PayloadAction<State['calendarEvents']>
    ) => ({
      ...state,
      calendarEvents: payload,
    }),
    setProgressCalendarEventIds: (
      state,
      { payload }: PayloadAction<State['progressCalendarEventIds']>
    ) => ({
      ...state,
      calendarEventMeta: payload,
    }),
    setIsLoading: (state, { payload }: PayloadAction<State['isLoading']>) => ({
      ...state,
      isLoading: payload,
    }),
  },
  extraReducers: (builder) =>
    builder.addCase(globalActions.resetStateAll, () => initialState),
});

export const actions = {
  ...reducerActions,
  connectToGoogleCalendar: createAction(
    'callRecordings/connectToGoogleCalendar'
  ),
  updateJoinPreference: createAction<Partial<UserCallSettings>>(
    'callRecordings/updateJoinPreference'
  ),
  toggleCalendarEventRecording: createAction<ToggleCalendarEventAction>(
    'callRecordings/toggleCalendarEventRecording'
  ),
  toggleCalendarEventVisibility: createAction<ToggleCalendarEventAction>(
    'callRecordings/toggleCalendarEventVisibility'
  ),
  loadCalendarEvens: createAction('callRecordings/loadCalendarEvens'),
};
