import { call, select, put } from 'redux-saga/effects';
import { callRecordingsApi } from '../../../../shared/api';
import { RT } from '../../../../shared/types';
import { teamsModel } from '../../../teams';
import { callRecordingSettingsModel } from '../index';
import { createNotification, snackbarModel } from '../../../snackbar';

export function* loadCallRecordingSettings() {
  const team: RT<typeof teamsModel.selectors.selectCurrentTeamWithError> =
    yield select(teamsModel.selectors.selectCurrentTeamWithError);

  try {
    const {
      teamSettings,
      userSettings,
      meetingCalendar,
      zoomCredentials,
    }: RT<typeof callRecordingsApi.getSettings> = yield call(
      callRecordingsApi.getSettings,
      { teamId: team.id }
    );

    yield put(callRecordingSettingsModel.actions.setTeamSettings(teamSettings));
    yield put(callRecordingSettingsModel.actions.setUserSettings(userSettings));
    yield put(
      callRecordingSettingsModel.actions.setMeetingCalendar(meetingCalendar)
    );
    yield put(
      callRecordingSettingsModel.actions.setZoomCredentials(zoomCredentials)
    );
  } catch (error) {
    yield put(
      snackbarModel.actions.addNotificationAction(
        createNotification('error', `Failed to get call recording settings`)
      )
    );
  }
}
