import { MeetingCalendarEvent } from '@distribute/shared/types';

type GroupedEvents = Record<string, MeetingCalendarEvent[]>;

export const groupByDate = (
  events: MeetingCalendarEvent[] = []
): GroupedEvents => {
  return events.reduce((acc, event) => {
    const date = new Date(event.startTime);
    const isToday = new Date().toDateString() === date.toDateString();

    if (isToday) {
      return {
        ...acc,
        Today: [...(acc.Today || []), event],
      };
    }
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
    });

    return {
      ...acc,
      [formattedDate]: [...(acc[formattedDate] || []), event],
    };
  }, {} as GroupedEvents);
};
