import { fork, takeEvery } from 'redux-saga/effects';
import { callRecordingSettingsModel } from '../index';

export function* callRecordingSettingsWorker() {
  yield takeEvery(
    callRecordingSettingsModel.actions.updateTeamCallRecordingSettings,
    callRecordingSettingsModel.sagas.updateTeamCallRecordingSettings
  );

  yield takeEvery(
    callRecordingSettingsModel.actions.updateUserCallRecordingSettings,
    callRecordingSettingsModel.sagas.updateUserCallRecordingSettings
  );

  // TODO: check where it should be added (settings, call recordings page)
  yield takeEvery(
    callRecordingSettingsModel.actions.connectToGoogleCalendar,
    callRecordingSettingsModel.sagas.connectToGoogleCalendar
  );

  yield takeEvery(
    callRecordingSettingsModel.actions.disconnectGoogleCalendar,
    callRecordingSettingsModel.sagas.disconnectGoogleCalendar
  );

  yield takeEvery(
    callRecordingSettingsModel.actions.connectZoom,
    callRecordingSettingsModel.sagas.connectZoom
  );

  yield takeEvery(
    callRecordingSettingsModel.actions.disconnectZoom,
    callRecordingSettingsModel.sagas.disconnectZoom
  );

  yield takeEvery(
    callRecordingSettingsModel.actions.connectToMsCalendar,
    callRecordingSettingsModel.sagas.connectToMsCalendar
  );

  yield takeEvery(
    callRecordingSettingsModel.actions.disconnectMsCalendar,
    callRecordingSettingsModel.sagas.disconnectMsCalendar
  );

  yield fork(callRecordingSettingsModel.sagas.watchCalendarConnection);

  yield fork(callRecordingSettingsModel.sagas.watchCalendarConnectionStatus);
}
