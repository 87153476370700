import { Task, TaskAssigneeExtended } from '@distribute/shared/types';
import { IconMap } from '../../../../src/shared/sprite';
import { Button, Dropdown, Icon, Toggle } from '../../../../src/shared/ui';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pagesModel } from '../../pages';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { DropdownItem } from '../../../../src/shared/ui/Dropdown';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { TaskNotesEditor } from './TaskNotesEditor';
import { format } from 'date-fns';
import { DueDateCalendar } from './DueDateCalendar';
import { useOnClickOutside } from '../../../../src/shared/hooks/useClickOutside';
import { UpdateTaskInput } from './TaskItem';
import { AssigneeTrigger } from './AssigneeTrigger';
import { cn } from '@distribute/frontend/utils';

type Props = {
  task: Task;
  assigneeOptions: DropdownItem[];
  onClose: () => void;
  assignee?: TaskAssigneeExtended;
  onTaskUpdate: (data: UpdateTaskInput) => void;
};

export const TaskModalContent: FC<Props> = ({
  task,
  assigneeOptions,
  onClose,
  onTaskUpdate,
  assignee,
}) => {
  const currentPage = useSelector(pagesModel.selectors.selectCurrentPage);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const calendarRef = useRef(null);

  const handleDueDateChange = (value: Date | null | (Date | null)[]) => {
    if (Array.isArray(value)) {
      return;
    }
    onTaskUpdate({ dueDate: value });
  };

  const clickOutsideHandler = () => {
    setIsCalendarOpen(false);
  };

  useOnClickOutside(calendarRef, clickOutsideHandler);

  return (
    <div className="relative">
      <button className="absolute -top-2.5 -right-17 z-1" onClick={onClose}>
        <Icon glyph={IconMap.Cross} width={12} className="text-gray-400" />
      </button>
      <div className="flex items-center justify-between gap-4 mb-4">
        <input
          placeholder="Untitled"
          className="flex-grow w-full text-2xl font-bold bg-transparent border-none outline-none placeholder:text-gray-400"
          value={task.title}
          onChange={(e) => {
            onTaskUpdate({ title: e.target.value });
          }}
        />

        <Button
          color={task.isDone ? 'secondary' : 'primary'}
          className="gap-0.5"
          size="md"
          variant="icon-text"
          onClick={() => {
            onTaskUpdate({ isDone: !task.isDone });
          }}
        >
          <Icon glyph={IconMap.CheckCircle} className="mr-1" />
          {task.isDone ? 'Mark as incomplete' : 'Mark as complete'}
        </Button>
      </div>
      <div className="mb-4">
        <ReactTextareaAutosize
          placeholder="Add a description here..."
          className="flex-grow w-full text-lg text-gray-900 bg-transparent border-none outline-none resize-none placeholder:text-gray-400"
          maxLength={200}
          minRows={1}
          maxRows={6}
          value={task.description}
          onChange={(e) => {
            onTaskUpdate({ description: e.target.value });
          }}
        />
        <span className="text-sm text-gray-500">
          {task.description.length}/200
        </span>
      </div>

      <hr className="bg-gray-200" />

      {currentPage && (
        <div className="flex items-center justify-start gap-6 py-4">
          <div className="flex items-center justify-start gap-3">
            <Icon glyph={IconMap.User03} className="text-gray-500" width={20} />
            <span className="text-gray-500 text-md">Assignee</span>
          </div>
          <Dropdown
            listStyles={cn('shadow-lg min-w-55')}
            alignOffset={-50}
            triggerComponent={
              <DropdownMenu.Trigger>
                {assignee ? (
                  <AssigneeTrigger assignee={assignee} isExtended />
                ) : (
                  <div className="text-gray-400 text-md">Not assigned</div>
                )}
              </DropdownMenu.Trigger>
            }
            items={assigneeOptions}
            isModalMode={false}
          />
        </div>
      )}

      <hr className="bg-gray-200" />

      <div className="flex items-center justify-start gap-6 py-4">
        <div className="flex items-center justify-start gap-3">
          <Icon glyph={IconMap.Calendar} className="text-gray-500" width={20} />
          <span className="text-gray-500 text-md">Due date</span>
        </div>
        <div className="relative">
          <button
            onClick={() => {
              setIsCalendarOpen(true);
            }}
            className={cn({
              'text-gray-400': !task.dueDate,
            })}
          >
            {task.dueDate
              ? format(new Date(task.dueDate), 'MMMM d, yyyy')
              : 'No due date'}
          </button>
          {isCalendarOpen && (
            <div
              ref={calendarRef}
              className="absolute px-6 pt-5 pb-4 border border-gray-200 z-[10000] top-8 -right-22 w-82 bg-base-white rounded-xl"
            >
              <DueDateCalendar
                dueDate={task.dueDate}
                onDueDateChange={handleDueDateChange}
              />
            </div>
          )}
        </div>
      </div>

      <hr className="bg-gray-200" />

      <div className="flex items-center justify-start gap-6 py-4">
        <div className="flex items-center justify-start gap-3">
          <Icon
            glyph={IconMap.CrossedEye}
            className="text-gray-500"
            width={20}
          />
          <span className="text-gray-500 text-md">Visibility</span>
        </div>
        <div className="flex items-center justify-start gap-2">
          <Toggle
            checked={task.isVisible}
            onChange={(value) => {
              onTaskUpdate({ isVisible: value });
            }}
          />
          <span>
            {task.isVisible ? 'Visible for viewers' : 'Invisible for viewers'}
          </span>
        </div>
      </div>

      <hr className="mb-10 bg-gray-200" />

      <TaskNotesEditor
        notes={task.notes}
        onNotesChange={(notes) => {
          onTaskUpdate({ notes });
        }}
      />
    </div>
  );
};
