import { selectWithError } from '../../../shared/lib';
import { RootState } from '../../../app';

export const selectCallList = ({ call }: RootState) => call.calls;
export const selectCallListOptions = ({ call }: RootState) => call.listOptions;
export const selectCallListMeta = ({ call }: RootState) => call.listMeta;
export const selectIsCallListLoading = ({ call }: RootState) =>
  call.isListLoading;

export const selectEditingCallItem = ({ call }: RootState) =>
  call.editingCallItem;
export const selectDeletingCallItem = ({ call }: RootState) =>
  call.deletingCallItem;

export const selectEditingCallItemWithError = selectWithError(
  selectEditingCallItem,
  'editingCallItem'
);

export const selectDeletingCallItemWithError = selectWithError(
  selectDeletingCallItem,
  'deletingCallItem'
);

export const selectIsCallEditingProgress = ({ call }: RootState) =>
  call.isCallEditingProgress;
export const selectIsCallDeletingProgress = ({ call }: RootState) =>
  call.isCallDeletingProgress;
