import { TasklistWithTasks } from '@distribute/shared/types';
import { formatDateShort } from '@distribute/shared/utils';

const CHECK_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/check.svg';
const TOGGLE_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/toggle-icon.svg';
const CALENDAR_GRAY_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/calendar-gray.svg';
const CALENDAR_LIGHT_ICON =
  'https://storage.googleapis.com/distribute-production-storage/general/calendar-light.svg';

export const getTasklistIdByRegex = (text: string) => {
  const regex = /\{\{\s*([^}\s]+)\s*\}\}/;
  const match = text.match(regex);

  if (match) {
    const id = match[1];
    return id;
  } else {
    return '';
  }
};

export const insertTasklistsIntoHTML = (
  htmlContent: string,
  tasklistsInfo: { id: string; template: string; info: TasklistWithTasks }[]
) => {
  let html = htmlContent;

  for (const tasklist of tasklistsInfo) {
    const id = tasklist.id;

    const currentTasklist = tasklistsInfo.find((s) => s.id === id);
    const tasklistInfo = currentTasklist?.info;

    if (tasklistInfo) {
      //tasklist heading
      const tasksAmount = tasklistInfo.tasks.filter((t) => t.isVisible).length;
      const completedTasksAmount = tasklistInfo.tasks.filter(
        (t) => t.isVisible && t.isDone
      ).length;

      const toggleIconHtml = `<img src=${TOGGLE_ICON} class="custom-tasklist__toggle ${
        tasklistInfo.isOpen ? 'custom-tasklist__toggle--rotated' : ''
      }"/>`;
      const toggleBtnHtml = `<button class="custom-tasklist__toggle-btn">${toggleIconHtml}</button>`;
      const listTitleHtml = `<p class="custom-tasklist__title">${tasklistInfo.title}</p>`;
      const titleWrapperHtml = `<div class="custom-tasklist__title-wrapper">${toggleBtnHtml}${listTitleHtml}</div>`;
      const completedHtml = `<span class="custom-tasklist__completed">${completedTasksAmount}/${tasksAmount} completed</span>`;
      const listHeadingHtml = `<div class="custom-tasklist__heading-wrapper">${titleWrapperHtml}${completedHtml}</div>`;

      const hrHtml = `<hr class="custom-tasklist__hr" />`;

      let tasksHtml = '';

      tasklistInfo.tasks
        .filter((t) => t.isVisible)
        .forEach((task) => {
          //title + checked
          const titleClassnames = `custom-tasklist__name ${
            task.isDone
              ? 'custom-tasklist__name--checked'
              : 'custom-tasklist__name--unchecked'
          }`;

          const titleParagraph = `<p>${task.title}</p>`;
          const titleHtmlString = `<div class="${titleClassnames}">${titleParagraph}</div>`;

          const iconString = `<img src=${CHECK_ICON} />`;
          const checkIconClassnames = `custom-tasklist__icon ${
            task.isDone
              ? 'custom-tasklist__icon--checked'
              : 'custom-tasklist__icon--unchecked'
          }`;
          const checkIconHtmlString = `<div class="${checkIconClassnames}">${iconString}</div>`;
          const nameString = `<div class="custom-tasklist__item-name-wrapper">${checkIconHtmlString}${titleHtmlString}</div>`;

          //date

          const dateText = `<span>${
            task.dueDate ? formatDateShort(task.dueDate.toString()) : 'none'
          }</span>`;
          const calendarIcon = `<img src=${
            task.dueDate ? CALENDAR_GRAY_ICON : CALENDAR_LIGHT_ICON
          } />`;

          const dateDiv = `<div class="custom-tasklist-item__date-wrapper ${
            task.dueDate
              ? 'custom-tasklist-item__date-wrapper--full'
              : 'custom-tasklist-item__date-wrapper--empty'
          }">${calendarIcon}${dateText}</div>`;

          //assignee

          const assigneeContent = task.assignee
            ? task.assignee?.user?.photoUrl
              ? `<img src=${task.assignee.user.photoUrl} />`
              : task.assignee.email[0].toUpperCase()
            : '';

          const assigneeDiv = `<div class="custom-tasklist-item__assignee-wrapper">${assigneeContent}</div>`;

          const detailsString = `<div class="custom-tasklist-item__details-wrapper">${
            task.assignee ? assigneeDiv : ''
          }${dateDiv}</div>`;

          const taskHtmlString = `<div class="custom-tasklist__item custom-tasklist__item-title-wrapper">${nameString}${detailsString}</div>`;

          tasksHtml += taskHtmlString;
        });

      const listContentHtml = `<div class="custom-tasklist__content">${tasksHtml}</div>`;

      const resHtml = `<div class="custom-tasklist">${listHeadingHtml}${hrHtml}${listContentHtml}</div>`;

      html = html.replace(`${tasklist.template}`, resHtml);
    }
  }

  return html;
};
