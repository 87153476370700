import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { IconMap } from '../../../shared/sprite';
import { Button, Link } from '../../../shared/ui';
import { callRecordingSettingsModel } from '../../call-recording-settings';

type Size = 'small' | 'large';

type Props = {
  size: Size;
};

export const ConnectCalendarState = ({ size = 'large' }: Props) => {
  const dispatch = useDispatch();
  const isCalendarConnecting = useSelector(
    callRecordingSettingsModel.selectors.selectIsCalendarConnecting
  );

  const handleCalendarConnect = () => {
    dispatch(callRecordingSettingsModel.actions.connectToGoogleCalendar());
  };

  return (
    <section className="flex flex-col justify-center items-center gap-4 max-w-128.5">
      <div className="bg-pattern-decorative bg-center bg-no-repeat">
        <div className="flex justify-center pb-6">
          <img
            className={cn({
              'w-3/4': size === 'small',
            })}
            src="./../../assets/images/upcoming-meetings.png"
            alt="Upcoming meetings"
          />
        </div>
        <div className="flex flex-col justify-center items-center gap-4 text-center">
          <div>
            <h3
              className={cn('text-gray-900 font-semibold', {
                'text-md': size === 'small',
                'text-lg': size === 'large',
              })}
            >
              Connect your work calendar to unlock all Distribute Call Recorder
              feature access.
            </h3>
            <p className="text-gray-600 text-sm font-normal">
              Choose a calendar you use for in your team.
            </p>
          </div>
          <div className="flex flex-col gap-4 text-center text-gray-600">
            <Button
              loading={isCalendarConnecting}
              variant="icon-text"
              color="secondary"
              className={cn('flex gap-3 w-full', {
                'text-sm': size === 'small',
                'text-md': size === 'large',
              })}
              iconLeftName={IconMap.Google}
              onClick={handleCalendarConnect}
            >
              Continue with Google
            </Button>
            {size === 'large' ? (
              <>
                or
                <p>
                  <Link size="large">Join Call Manually</Link> directly with a
                  link.
                </p>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};
